<template>
  <div id="app">
    <product-introduce />
  </div>
</template>

<script>
import ProductIntroduce from "./components/product-introduce.vue";

export default {
  name: "App",
  components: {
    ProductIntroduce,
  },
};
</script>

<style>
#app {
  font-family: PingFang SC;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  /* color: #2c3e50; */
  margin: 0px;
}
body {
  margin: 0;
}
</style>
