<template>
  <!-- 产品介绍页面 -->
  <div class="hello">
    <div class="top-button fixed-top">
      <div class="button-class introduce-left">
        <img class="introduce-img" :src="require('@/assets/logo.png')" />
        <h1 class="introduce-text">企安检</h1>
      </div>
      <div class="button-class button-right">
        <button class="login-button" @click="handleLogin">登录</button>
        <button class="register-button" @click="handleRegister">
          快速注册
        </button>
      </div>
    </div>
    <div class="div-group">
      <div class="div-group-gif">
        <img
          class="class-img"
          :src="require('@/assets/register.gif')"
          alt="智能识别隐患---随身安全检查助手"
        />
        <img
          class="register-first-img img-click"
          :src="require('@/assets/register-first-button.gif')"
          alt="智能识别隐患---随身安全检查助手---注册按钮"
          @click="handleRegister"
        />
      </div>
    </div>

    <div class="div-group-law-safe">
      <div class="div-group-law-safe-gif">
        <img
          class="class-img"
          :src="require('@/assets/law-safe-data.gif')"
          alt="安全法规、隐患依据"
        />
        <img
          class="div-group-law-safe-img img-click"
          :src="require('@/assets/register-first-button.gif')"
          alt="安全法规、隐患依据---注册按钮"
          @click="handleRegister"
        />
      </div>
    </div>

    <div class="div-group-ai-model">
      <img
        class="class-img"
        :src="require('@/assets/ai-model.gif')"
        alt="AI大模型"
      />
    </div>

    <div class="div-group-app-law">
      <img
        class="class-img"
        :src="require('@/assets/app-law-data.gif')"
        alt="app法律法规多种数据库"
      />
    </div>

    <div class="div-group-app-speech">
      <img
        class="class-img"
        :src="require('@/assets/app-speech.gif')"
        alt="app语音搜索"
      />
    </div>

    <div class="div-group-app-photograph">
      <img
        class="class-img"
        :src="require('@/assets/app-photograph.gif')"
        alt="app拍照记录报告"
      />
    </div>

    <div
      class="div-group-app-team"
      @mouseover="toggleDynamicImage(true)"
      @mouseleave="toggleDynamicImage(false)"
    >
      <img class="class-img" :src="dynamicImage" alt="app团队协作" />
    </div>

    <div class="div-background">
      <img
        class="class-img"
        :src="require('@/assets/product-background.png')"
        alt="产品介绍"
      />
    </div>

    <div class="div-filing">
      <h4 class="filing-context">
        威海软件开发,企业级安全平台,应急管理平台,智慧化工国区,低代码平台
      </h4>
      <h4 class="filing-context">
        地址:山东省威海市环翠区文化中路-59-7号2-3层
        &nbsp;&nbsp;&nbsp;&nbsp;联系人:吴先生 &nbsp;&nbsp;&nbsp;电话:18663117660
      </h4>
      <div class="div-box">
        <h4 class="filing-context">山东新派信息技术有限公司</h4>
        <a
          href="https://beian.miit.gov.cn/"
          class="link-record-number"
          target="_blank"
        >
          鲁ICP备2022022576号-5</a
        >
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ProductIntroduce",
  data() {
    return {
      gifImg: [
        { url: require("@/assets/app-team.gif") },
        { url: require("@/assets/app-team1.png") },
      ],
      dynamicImage: require("@/assets/app-team1.png"),
    };
  },
  props: {},
  methods: {
    // 跳转登录
    handleLogin() {
      window.open("https://admin.7anjian.com/login");
    },
    // 跳转注册
    handleRegister() {
      window.open("https://admin.7anjian.com/register");
    },
    //判断鼠标是否移入显示动态图
    toggleDynamicImage(isActive) {
      if (isActive) {
        this.dynamicImage = this.gifImg[0].url; // 鼠标移入时显示动态图
      } else {
        this.dynamicImage = this.gifImg[1].url; // 鼠标移出时显示静态图
      }
    },
  },
};
</script>

<style scoped>
.top-button {
  background: #f6f7fb;
  display: flex;
  justify-content: space-between;
  height: 7vh;
  z-index: 1999;
}
.fixed-top {
  width: 100%;
  top: 0px;
  left: 0px;
  position: fixed;
}
.button-class {
  display: flex;
  align-items: center;
}
.introduce-left {
  justify-content: start;
  margin-left: 3%;
}
.introduce-img {
  background-size: 100% 100%;
  height: 3vh;
  width: 3vh;
}
.introduce-text {
  margin: 0px 10px;
  font-size: 2.5vh;
  color: #3582ff;
}
.button-right {
  justify-content: end;
  margin-right: 3%;
}
.img-click {
  cursor: pointer;
}
.login-button {
  background: rgba(245, 247, 250, 0);
  color: #000000;
  border: 1px solid #cad2dd;
  border-radius: 20px;
  padding: 10px 30px;
  margin-right: 10px;
  font-weight: bold;
  cursor: pointer;
  /* height: 3vh; */
}
.register-button {
  background: #2084ff;
  color: #fff;
  border: none;
  padding: 10px 30px;
  border-radius: 20px;
  font-weight: bold;
  cursor: pointer;
}
.div-group {
  margin-top: 7vh;
  height: 800px;
  background-image: url("@/assets/register-bg.jpg");
  background-size: cover; /* 让背景图片铺满容器 */
  background-repeat: no-repeat; /* 不重复平铺 */
  background-position: center center; /* 图片居中显示 */
}
.div-group-gif {
  position: relative;
  height: 800px;
}
.register-first-img {
  background-size: 100% 100%;
  object-fit: contain;
  position: absolute;
  top: 380px;
  left: 28%;
}
.div-group-law-safe {
  height: 800px;
  background-image: url("@/assets/law-safe-bg.png");
  background-size: cover; /* 让背景图片铺满容器 */
  background-repeat: no-repeat; /* 不重复平铺 */
  background-position: center center; /* 图片居中显示 */
}
.div-group-law-safe-gif {
  position: relative;
  height: 800px;
}
.div-group-law-safe-img {
  background-size: 100% 100%;
  object-fit: contain;
  position: absolute;
  top: 300px;
  left: 45%;
}
.div-group-ai-model {
  height: 800px;
  background-image: url("@/assets/ai-model-bg.jpg");
  background-size: cover; /* 让背景图片铺满容器 */
  background-repeat: no-repeat; /* 不重复平铺 */
  background-position: center center; /* 图片居中显示 */
}
.div-group-app-law {
  height: 800px;
  background-image: url("@/assets/app-law-data-bg.jpg");
  background-size: cover; /* 让背景图片铺满容器 */
  background-repeat: no-repeat; /* 不重复平铺 */
  background-position: center center; /* 图片居中显示 */
}
.div-group-app-speech {
  height: 800px;
  background-image: url("@/assets/app-speech-bg.jpg");
  background-size: cover; /* 让背景图片铺满容器 */
  background-repeat: no-repeat; /* 不重复平铺 */
  background-position: center center; /* 图片居中显示 */
}
.div-group-app-photograph {
  height: 800px;
  background-image: url("@/assets/app-photograph-bg.jpg");
  background-size: cover; /* 让背景图片铺满容器 */
  background-repeat: no-repeat; /* 不重复平铺 */
  background-position: center center; /* 图片居中显示 */
}
.div-group-app-team {
  height: 800px;
  background-image: url("@/assets/app-team-bg.jpg");
  background-size: cover; /* 让背景图片铺满容器 */
  background-repeat: no-repeat; /* 不重复平铺 */
  background-position: center center; /* 图片居中显示 */
}
.class-img {
  object-fit: contain;
  height: 100%;
}
.div-background {
  height: 493px;
  background-image: url("@/assets/product-bg.jpg");
  background-size: cover; /* 让背景图片铺满容器 */
  background-repeat: no-repeat; /* 不重复平铺 */
  background-position: center center; /* 图片居中显示 */
}
.div-filing {
  display: flex;
  flex-direction: column;
  height: 250px;
  background: #000000;
  justify-content: center;
  align-items: center;
}
.filing-context {
  font-family: PingFang SC;
  font-size: 12px;
  color: #ffffff;
  margin: 10px 0px;
}
.div-box {
  display: flex;
  align-items: center;
}
.link-record-number {
  color: #e6a23c;
  font-size: 12px;
  text-decoration: none;
  margin-left: 20px;
}
</style>
